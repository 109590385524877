import React from 'react';
import PortfolioBlock from './PortfolioBlock';
import { Box, Grid } from '@mui/material';
import { info } from '../../assets/info/Info';

export default function Portfolio({ darkMode }) {
  return (
    <Box>
      <h2 display="flex" justifyContent="center" alignItems="center">
        Experience
      </h2>
      <Box container>
        {info.experience.map((project, index) => (
          <Box
            item
            xs={12}
            md={6}
            key={index}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <PortfolioBlock
              image={project.image}
              live={project.live}
              source={project.source}
              title={project.title}
              description={project.description}
              darkMode={darkMode}
            />
          </Box>
        ))}
      </Box>
      <h2>Projects</h2>
      <Grid container display={'flex'} justifyContent={'center'}>
        {info.portfolio.map((project, index) => (
          <Box
            item
            xs={12}
            md={6}
            key={index}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <PortfolioBlock
              image={project.image}
              live={project.live}
              source={project.source}
              title={project.title}
              description={project.description}
              darkMode={darkMode}
            />
          </Box>
        ))}
      </Grid>
    </Box>
  );
}
