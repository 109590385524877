import self from '../img/self.png';
import { colors } from '../colors/mainGradient';

export const info = {
  firstName: 'Ameer',
  lastName: 'Hassan',
  initials: 'AH', // the example uses first and last, but feel free to use three or more if you like.
  position: 'a Full Stack Developer',
  selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
  baseColor: colors[0],
  miniBio: [
    // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
    {
      emoji: '🌎',
      text: 'Based in New Jersey',
    },
    {
      emoji: '💻',
      text: 'Applications Developer III @ UC Davis Health',
    },
    {
      emoji: '📧',
      text: 'ameeryaserhassan@gmail.com',
    },
  ],
  socials: [
    {
      link: 'https://ameer.codes/ahassan_resume.pdf', // this should be https://yourname.com/resume.pdf once you've deployed
      icon: 'fa fa-file',
      label: 'resume',
    },
    {
      link: 'https://github.com/AmeerYHassan',
      icon: 'fa fa-github',
      label: 'github',
    },
    {
      link: 'https://www.linkedin.com/in/ameeryhassan/',
      icon: 'fa fa-linkedin',
      label: 'linkedin',
    },
  ],
  bio: "Hello! I'm Ameer Hassan, a Full Stack Developer. I received my B.S. in Computer Science from NJIT. Currently, I work at FiscalNote (Curate) as a Full Stack Developer. Apart from my developer role, I also run my own business called SurrealAqua. SurrealAqua specializes in providing 3D Printed tools to aquarium keepers.",
  skills: {
    languages: ['Python', 'TypeScript', 'JavaScript', 'Java', 'HTML/CSS'],
    frameworks: ['React', 'Angular', 'Material UI', 'Puppeteer'],
    databases: ['MySQl', 'MongoDB', 'PostgreSQL'],
    cloudServices: ['AWS S3', 'AWS EC2', 'AWS Cloud 9', 'Heroku', 'Netlify'],
    tools: ['Git', 'Github', 'Postman', 'Docker', 'Kubernetes', 'OAuth', 'LaTeX', 'Agile', 'Scrum'],
  },
  hobbies: [
    {
      label: 'Aquariums',
      emoji: '🐠',
    },
    {
      label: '3D Printing',
      emoji: '🪛',
    },
    {
      label: 'Woodworking',
      emoji: '🪵',
    },
    {
      label: 'Video Games',
      emoji: '🎮',
    },
    {
      label: 'Traveling',
      emoji: '✈️',
    },
    {
      label: 'Movies',
      emoji: '🍿',
    },
    {
      label: 'Music',
      emoji: '🎧',
    },
    // Same as above, change the emojis to match / relate to your hobbies or interests.
    // You can also remove the emojis if you'd like, I just think they look cute :P
  ],
  // portfolio: [
  //   // This is where your portfolio projects will be detailed
  //   //   for the null ones, the button will not show up
  //   {
  //     title: 'Coming Soon',
  //     live: false,
  //     source: '',
  //     image: '',
  //   },
  // ],
  portfolio: [
    // This is where your portfolio projects will be detailed
    //   for the null ones, the button will not show up
    {
      title: 'Open Source contributions to pyCGM',
      live: null,
      source: 'https://github.com/cadop/pyCGM',
      image:
        'https://opengraph.githubassets.com/d4f3d30a44335ca4b699eb69d0c082069b52a41ea1ba83e558c52819daa15fe4/cadop/pyCGM',
      description:
        'In a team of five, we worked on pyCGM, an open-source Python module for gait analysis. Our goal was to improve its performance and efficiency. By thoroughly analyzing the code, we identified and refactored inefficiencies, resulting in overall performance enhancements. We implemented a custom data structure for more efficient input and output processing. Additionally, we replaced inefficient calculations with functions from external libraries like NumPy and SciPy. To ensure code reliability, we wrote unittests using PyTest and the standard unittest library. Throughout the project, we followed the Agile/Scrum methodology, including sprints, bi-weekly meetings, and daily standups.',
    },
    {
      title: 'Discord Voice Tracking Bot',
      live: null, //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      source: 'https://github.com/AmeerYHassan/Voice-Logger', // this should be a link to the **repository** of the project, where the code is hosted.
      image:
        'https://user-images.githubusercontent.com/57303145/123700908-1e661080-d82f-11eb-9e1a-837b8be6eb8d.png',
      description:
        'I developed a voice logging Discord bot using Python and the discord.py module. The bot monitors and logs voice-chat activity on the Discord platform. By utilizing asynchronous programming, the bot can run on multiple Discord servers simultaneously. It records information such as user entry and exit from voice channels and stores the data in a MongoDB database for persistence. Additionally, users can access server statistics, voice-chat history, and personal user statistics through a custom-implemented Discord command. The bot leverages technologies like Git, REST APIs, and Amazon AWS for its functionality.',
    },
  ],

  experience: [
    {
      title: 'UC Davis Health',
      time: 'Oct. 2023 - Present',
      live: null,
      source: '',
      image: 'https://i.imgur.com/BXmc6gX.png',
      description:
        "Since joining UC Davis Health in October 2023, I have played an important role in maintaining and expanding internal tools utilized by the staff. Additionally, I led the development of a financial aid tracker, integrating data from various sources within the organization. This project streamlined fund management for students, enhancing staff efficiency. Working on diverse projects, each with unique requirements and tech stacks, I adapted to different roles frequently. I engaged directly with end users during development and employed CI/CD with Jenkins for deploying across multiple environments.",
    },
    {
      title: 'FiscalNote (Curate)',
      time: 'Feb. 2022 - Aug. 2023',
      live: null,
      source: '',
      image: 'https://i.imgur.com/Ox67C1N.png',
      description:
        "As a Software Engineer at FiscalNote (Curate) from Feb. 2022 to Aug. 2023, I have been involved in various responsibilities. I optimized the efficiency and accuracy of a web scraping system that captures meeting minutes and agendas from municipal websites nationwide through continuous patches and updates. Additionally, I led the creation of a comprehensive directory encompassing meeting minutes and agendas from numerous municipalities across the United States. I acted as a point of contact for technical and non-technical staff, providing support and guidance on software systems. I also conducted seamless deployments of new features, bug fixes, and updates to the company's website. Collaborating within an Agile team, I contributed to efficient development processes and assisted in onboarding new team members.",
    },
  ],
};
