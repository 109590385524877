import React from 'react';
import IconLink from './IconLink';
import { Box } from '@mui/material';
import './portfolio.css';

function PortfolioBlock(props) {
  const { image, live, source, title, description, darkMode } = props;
  console.log(description);
  return (
    <Box
      style={{
        borderRadius: '1rem',
        background: darkMode ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)',
        boxShadow: darkMode
          ? '0 0 10px 0 rgba(0, 0, 0, 0.2)'
          : '0 0 10px 0 rgba(255, 255, 255, 0.2)',
        backdropFilter: 'blur(8px)',
        WebkitBackdropFilter: 'blur(8px)',
        padding: '1rem 2rem',
        margin: '20px 20px 20px 20px',
        transition: 'all 0.3s ease',
        width: '40vw',
        display: 'flex',
      }}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {
        <Box
          component={'img'}
          src={image}
          alt={'mockup'}
          width={'40%'}
          style={{
            objectFit: 'cover',
            borderRadius: '25px',
            boxShadow:
              '  0 2.8px 2.2px rgba(0, 0, 0, 0.034),\n' +
              '  0 6.7px 5.3px rgba(0, 0, 0, 0.048),\n' +
              '  0 12.5px 10px rgba(0, 0, 0, 0.06),\n' +
              '  0 22.3px 17.9px rgba(0, 0, 0, 0.072),\n' +
              '  0 41.8px 33.4px rgba(0, 0, 0, 0.086),\n' +
              '  0 100px 80px rgba(0, 0, 0, 0.12)',
          }}
        />
      }
      <h1 style={{ fontSize: '2rem' }}>{title}</h1>
      <Box
        className={'portfolio'}
        display={'flex'}
        flexDirection={'column'}
        gap={'0.5rem'}
        alignItems={'center'}
        fontSize={'1.5rem'}
        py={'2rem'}
      >
        {live && (
          <Box p={1} border={'2px solid black'} borderRadius={'25px'}>
            <IconLink link={live} title={'Live Demo'} icon={'fa fa-safari'} />
          </Box>
        )}

        {source && (
          <Box p={1} border={'2px solid black'} borderRadius={'25px'}>
            <IconLink link={source} title={'Source Code'} icon={'fa fa-code'} />
          </Box>
        )}

        <p className="description-size">{description}</p>
      </Box>
    </Box>
  );
}

export default PortfolioBlock;
