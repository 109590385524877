import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCEwAhyOl5omWS7bj6WSjfP3ZsLvMC8SK0",
  authDomain: "ameerportfolio-1685669469766.firebaseapp.com",
  projectId: "ameerportfolio-1685669469766",
  storageBucket: "ameerportfolio-1685669469766.appspot.com",
  messagingSenderId: "503330577050",
  appId: "1:503330577050:web:2a8d90e18ddb920f859d23",
  measurementId: "G-QSNWCRY511"
};

const app = initializeApp(firebaseConfig);

const isGaSupported = isSupported();

const analytics = isGaSupported ? getAnalytics(app) : null;

export const logGa = (eventName, eventParams = {}) => {
  isGaSupported ? logEvent(analytics, eventName, eventParams) : console.log(eventName, eventParams);
};
